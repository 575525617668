import Navbar from "../Navbar";
import Footer from "../Footer";
import Button from "../Buttons";
import CTA from "../CTA";
import ThreeStepAction from "../ThreeStepAction";
import Newsletter from "../Newsletter";
import { Transition, TransitionChild } from "@headlessui/react";
import { useRef, useState } from "react";

import {
  faToilet,
  faPaintBrush,
  faPencil,
  faShapes,
  faUtensils,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

import carolynHeadshot from "../Assets/CarolynHeadshot.png";
import loriHeadshot from "../Assets/LoriHeadshot.png";
import kellyHeadshot from "../Assets/KellyHeadshot.png";
import childWithTherapist from "../Assets/childWithTherapist.jpg";
import childCloseUp from "../Assets/closeHappy.jpg";
import ScrollTransition from "../ScrollTransition";
import FAQ from "../FAQ";
import TeamSection from "../TeamSection";

const services = [
  {
    title: "Self-Care",
    href: "#",
    icon: faToilet,
    iconForeground: "text-teal-800",
    iconBackground: "bg-teal-100",
    textBackground: "bg-teal-300",
    description:
      "Self-care struggles like feeding, picky eating, dressing, brushing teeth, and toileting",
  },
  {
    title: "School Skills",
    href: "#",
    icon: faPaintBrush,
    iconForeground: "text-purple-800",
    iconBackground: "bg-purple-100",
    textBackground: "bg-purple-300",
    description: "School/preschool skills and body awareness.",
  },
  {
    title: "Motor Planning",
    href: "#",
    icon: faPencil,
    iconForeground: "text-sky-800",
    iconBackground: "bg-sky-100",
    textBackground: "bg-sky-300",
    description:
      "Motor planning, fine motor skills, and visual motor skills impacting on colouring, drawing, printing and writing.",
  },
  {
    title: "Play / Social Skills",
    href: "#",
    icon: faShapes,
    iconForeground: "text-yellow-800",
    iconBackground: "bg-yellow-100",
    textBackground: "bg-yellow-300",
    description:
      "Play/social skills such as emotional regulation, waiting, taking turns, and calming their body so they can communicate. Also exploring the impact of the environment and their ability to process sensory information to communicate, learn and play.",
  },
  {
    title: "Speech and Language delays",
    href: "#",
    icon: faComments,
    iconForeground: "text-pink-800",
    iconBackground: "bg-pink-100",
    textBackground: "bg-pink-300",
    description:
      "Language disorders, developmental disabilities and motor speech disorders.",
  },
  {
    title: "Feeding",
    href: "#",
    icon: faUtensils,
    iconForeground: "text-orange-800",
    iconBackground: "bg-orange-100",
    textBackground: "bg-orange-300",
    description:
      "Feeding therapy to help with picky eating, transitioning between food types, and sensory issues.",
  },
];

const employees = [
  {
    name: "Carolyn Slomka",
    role: "CEO / Senior Occupational Therapist",
    imageUrl: carolynHeadshot,
    bio: (
      <p>
        For the past 30 years Carolyn has worked as an O.T. with over 20 years
        experience working in children's treatment centres in Toronto and
        Kitchener/Waterloo where she championed adaptive programs such as
        wheelchair hockey at Holland Bloorview and Go Baby Go at Kidsability.
        <br />
        <br />
        In the last 8 years Carolyn has focused on working with preschoolers
        with a variety of developmental delays. Carolyn brings a love for
        children and families to her collaborative approach to healthcare.
      </p>
    ),
    email: "carolyn@embracegains.com",
    phoneNumber: "(548) 255-3824",
  },
  {
    name: "Lori Hill",
    role: "Occupational Therapist",
    imageUrl: loriHeadshot,
    bio: (
      <p>
        Lori is passionate about her role as an Occupational Therapist and for
        the past 26 years worked at KidsAbility in both the Early Years and
        School Years programs.
        <br />
        <br />
        Lori has experience in numerous areas of daily living for clients 18
        years of age and younger. Lori's focus is on the client/family's goals,
        working together to create a joint plan.
      </p>
    ),
    email: "lori.hill@embracegains.com",
    phoneNumber: "(548) 255-3824",
  },
  {
    name: "Kelly Reitzel",
    role: "Speech & Language Pathologist",
    imageUrl: kellyHeadshot,
    bio: (
      <p>
        Kelly has been practicing as a Speech Language Pathologist since 2017
        with a focus on paediatrics and a desire to partner with families.
        <br />
        <br />
        Kelly demonstrates skills ranging from client education and consultation
        to direct therapy services.
        <br />
        <br />
        Kelly believes in therapy that transcends the clinic room with
        transferable skills that enhance a client's ability to participate in
        their community.
      </p>
    ),
    email: "kelly.reitzel@embracegains.com",
    phoneNumber: "(548) 255-3824",
  },
];

export default function LandingExample({ isShowing }) {
  const navigation = [
    { name: "Home", ref: "home", target: "_self", current: true },
    { name: "Services", ref: useRef(), target: "_self", current: false },
    {
      name: "Booking",
      ref: "https://embracegains.clinicmaster.com/landing?clinicId=3150&lang=en-CA",
      target: "_blank",
      current: false,
    },
    { name: "About", ref: useRef(), target: "_self", current: false },
  ];
  const [nav, setNav] = useState(navigation);

  return (
    <div className="bg-white font-acme">
      <div className="bg-white">
        <main>
          <Navbar navigation={nav} update={setNav} />

          <div className="bg-gradient-to-l from-purple-600 to-purple-400 py-10 sm:py-16 lg:overflow-hidden lg:py-14 xl:py-28">
            <div className="mx-auto my-auto max-w-7xl md:px-5">
              <Transition show={isShowing}>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center md:max-w-3xl lg:flex lg:items-center lg:px-0 lg:text-left">
                    <TransitionChild
                      appear="true"
                      enter="transition-opacity delay-200 duration-700"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-700"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <TransitionChild
                        appear="true"
                        enter="transition ease-in-out duration-700 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-700 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                      >
                        <div className="lg:py-24">
                          <h1 className="mt-4 text-center text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-left xl:text-6xl">
                            <span className="block">Children's Therapy</span>
                            <span className="block bg-gradient-to-r from-yellow-200 to-yellow-400 bg-clip-text pb-3 text-5xl text-transparent sm:pb-5 sm:text-7xl">
                              Services
                            </span>
                          </h1>
                          <p className="text-center text-xl text-white sm:text-xl lg:text-lg  xl:text-left xl:text-2xl">
                            We believe each child is unique and thrives in their
                            own ways.
                          </p>
                          <div className="mt-10 sm:mt-12">
                            <div className="md:flex md:justify-center xl:justify-start">
                              <div className="text-center sm:mt-0 sm:ml-3 ">
                                <Button
                                  size="xxl"
                                  bgColour="bg-purple-700"
                                  hoverColour="hover:bg-purple-800"
                                  onClick={() => {
                                    nav[1].ref.current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  }}
                                >
                                  OT Services
                                </Button>

                                <Button
                                  size="xxl"
                                  bgColour="bg-purple-700"
                                  hoverColour="hover:bg-purple-800"
                                  onClick={() => {
                                    setTimeout(() => {
                                      nav[1].ref.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                    }, 50);
                                  }}
                                  className="ml-5"
                                >
                                  SLP Services
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TransitionChild>
                    </TransitionChild>
                  </div>
                  <TransitionChild
                    appear="true"
                    enter="transition-opacity delay-300 duration-1000"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-1000"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="aspect-w-2 aspect-h-1 mx-auto mt-7 max-w-4xl scale-90 sm:mt-0 sm:scale-75 md:-mt-4 lg:aspect-w-1 lg:aspect-h-1 lg:mx-0 lg:mt-0 lg:scale-100">
                      <img
                        className="pointer-events-none rounded-lg object-cover object-left shadow-xl ring-1 ring-black ring-opacity-5"
                        src={childCloseUp}
                        alt=""
                      />
                    </div>
                  </TransitionChild>
                </div>
              </Transition>
            </div>
          </div>

          <div ref={navigation[3].ref}>
            <TeamSection people={employees} />
          </div>

          {/* Feature section with grid */}

          <div ref={navigation[1].ref} className="bg-white py-16 md:py-32">
            <ScrollTransition>
              <div className="relative text-center">
                <FAQ options={services}>
                  <div className="relative mb-8 full md:w-3/4 md:mx-auto rounded-3xl">
                    <img
                      className="rounded-3xl aspect-2 object-cover object-left-bottom"
                      src={childWithTherapist}
                      alt=""
                    />
                    <div className="bg-white/85 backdrop-blur-sm w-full rounded-b-3xl py-2 md:py-4 absolute inset-x-0 left-1/2 transform -translate-x-1/2 -translate-y-full">
                      <h2 className="text-2xl font-semibold text-purple-700">
                        Services
                      </h2>
                      <p className="my-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        How can we help?
                      </p>
                    </div>
                  </div>
                </FAQ>
              </div>
            </ScrollTransition>
          </div>

          {/* CTA */}
          <ScrollTransition>
            <CTA />
          </ScrollTransition>

          {/* Three Step Action Section */}
          <ScrollTransition>
            <ThreeStepAction />
          </ScrollTransition>

          {/* Newsletter Section */}
          <ScrollTransition>
            <Newsletter />
          </ScrollTransition>
        </main>
        <Footer />
      </div>
    </div>
  );
}
